import React from "react";

// function article_1() {
// 	return {
// 		date: "7 May 2023",
// 		title: "The Benefits of Cloud Computing",
// 		description:
// 			"Cloud computing offers a range of benefits, including cost savings and increased flexibility. Find out why more businesses are turning to the cloud.",
// 		keywords: [
// 			"The Benefits of Cloud Computing",
// 			"Dagrooms",
// 			"Daniel G",
// 			"Daniel Grooms",
// 		],
// 		style: `
// 				.article-content {
// 					display: flex;
// 					flex-direction: column;
// 					align-items: center;
// 				}

// 				.randImage {
// 					align-self: center;
// 					outline: 2px solid red;
// 				}
// 				`,
// 		body: (
// 			<React.Fragment>
// 				<div className="article-content">
// 					<div className="paragraph">Here's some stuff about the cloud.</div>
// 				</div>
// 			</React.Fragment>
// 		),
// 	};
// }

// const myArticles = [article_1];
const myArticles = [];

export default myArticles;
