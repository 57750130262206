const INFO = {
	main: {
		title: "Dagrooms Portfolio",
		name: "Daniel Grooms",
		email: "mail@dagrooms.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/dagrooms52",
		linkedin: "https://www.linkedin.com/in/daniel-g-10a59090/",
	},

	homepage: {
		title: "Full-stack developer in cloud computing, hobbyist game developer",
		description:
			"I'm Daniel Grooms, a backend developer with expertise in Azure, C#, and Python. I've worked at Microsoft on Azure App Service and Hololens. I make indie games in Godot Engine with multiplayer support and millions of sprites.",
	},

	about: {
		title: "I'm Daniel Grooms. I make cloud services, websites, and games",
		description:
			"",
	},

	articles: {
		title: "My thoughts on software development, technology, game development, and more",
		description:
			"",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/csharp/csharp.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"",
			logo: "godot.svg",
			linkText: "View Project",
			link: "https://github.com",
		},

	],
};

export default INFO;
